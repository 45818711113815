import React, { Component } from "react";
import { GetProductDetails, GetCategoryDetails } from "../../../services";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addToCart } from "../../../../store/actions/cartActions";
import { NotificationManager } from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";

class Productview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            categorybyproduct: [],
            subcategories: [],
            isloaded: false,
            toggle: false,
            limit: 3,
        };
    }

    async getFilterByProduct() {
        this.setState({ isloaded: false });
        let url = window.location.href.split("/");
        const lastSegment = url.pop() || url.pop();
        try {
            const p = await GetProductDetails.getProductByFilter(lastSegment);
            console.log("Fetched product data by filter:", p.data); // Log the fetched data
            if (p) {
                this.setState({ list: p.data, isloaded: true });
            }
        } catch (e) {
            NotificationManager.error("Empty data in category", "Data");
        }
    }

    async fetchSubCategories() {
        try {
            const subcategories = await GetCategoryDetails.getAllCategorySubCategoryList();
            if (subcategories) {
                this.setState({ subcategories: subcategories.data, isloaded: true });
            } else {
                NotificationManager.error("Failed to fetch subcategories.");
            }
        } catch (error) {
            console.error("Error fetching subcategories:", error);
            NotificationManager.error("Error fetching subcategories.");
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.getFilterByProduct();
        this.fetchSubCategories(); // Fetch subcategories when the component mounts
    }

    componentDidUpdate(prevProps) {
        // Compare previous and current props to decide if data should be refetched
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.getFilterByProduct();
        }
    }

    handleAddToCartClick = (product) => {
        // Navigate to the SingleProduct page with only product ID
        this.props.history.push(`/p/${product.id}`);
    };

    async handleFilterCategory(subcategory) {
        try {
            const category = await GetProductDetails.getProductByCategory(subcategory.id);
            console.log("Fetched products by category:", category.data); // Log the fetched data
            if (category) {
                this.setState({
                    categorybyproduct: category.data,
                    isloaded: true,
                    toggle: true,
                });
            } else {
                NotificationManager.error("Empty data in category", "Undefined");
            }
        } catch (error) {
            NotificationManager.error("Error fetching products by category.");
        }
    }

    render() {
        const { list, subcategories, categorybyproduct, toggle, isloaded, limit } = this.state;
        return (
            <div>
                <section className="pt-3 pb-3 page-info section-padding border-bottom bg-white single-product-header-bk">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <a href="#">
                                    <strong>
                                        <span className="mdi mdi-home" /> Home
                                    </strong>
                                </a>{" "}
                                <span className="mdi mdi-chevron-right" />{" "}
                                <a href="#">Embroidery Items</a>{" "}
                                <span className="mdi mdi-chevron-right" />{" "}
                                <a href="#">Categories</a>
                            </div>
                        </div>
                    </div>
                </section>

                {/* All products */}
                <section className="shop-list section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="shop-filters">
                                    <div id="accordion">
                                        <div className="card">
                                            <div className="card-header" id="headingOne">
                                                <h5 className="mb-0">
                                                    <button
                                                        className="btn btn-link"
                                                        data-toggle="collapse"
                                                        data-target="#collapseOne"
                                                        aria-expanded="true"
                                                        aria-controls="collapseOne"
                                                    >
                                                        All Category{" "}
                                                        <span className="mdi mdi-chevron-down float-right" />
                                                    </button>
                                                </h5>
                                            </div>
                                            <div
                                                id="collapseOne"
                                                className="collapse show"
                                                aria-labelledby="headingOne"
                                                data-parent="#accordion"
                                            >
                                                <div className="card-body card-shop-filters">
                                                    {isloaded
                                                        ? subcategories.map((subcategory, index) => (
                                                            <div className="card-body" key={index}>
                                                                <div className="list-group">
                                                                    <span
                                                                        className="list-group-item list-group-item-action"
                                                                        onClick={() => this.handleFilterCategory(subcategory)}
                                                                    >
                                                                        {subcategory.name}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ))
                                                        : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-9">
                                <div className="shop-head">
                                    <a href="#">
                                        <span className="mdi mdi-home" /> Home
                                    </a>{" "}
                                    <span className="mdi mdi-chevron-right" />{" "}
                                    <a href="#">Embroidery Items</a>{" "}
                                    <span className="mdi mdi-chevron-right" />{" "}
                                    <a href="#">Categories</a>
                                    <div className="btn-group float-right mt-2">
                                        <button
                                            type="button"
                                            className="btn btn-dark dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            Sort by Products &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </button>
                                        <div className="dropdown-menu dropdown-menu-right">
                                            <a className="dropdown-item" href="#">
                                                Relevance
                                            </a>
                                            <a className="dropdown-item" href="#">
                                                Price (Low to High)
                                            </a>
                                            <a className="dropdown-item" href="#">
                                                Price (High to Low)
                                            </a>
                                            <a className="dropdown-item" href="#">
                                                Discount (High to Low)
                                            </a>
                                            <a className="dropdown-item" href="#">
                                                Name (A to Z)
                                            </a>
                                        </div>
                                    </div>
                                    <h5 className="mb-3">Categories</h5>
                                </div>
                                {!isloaded ? (
                                    <div className="progress-bar-bk">
                                        <CircularProgress color="secondary" />
                                    </div>
                                ) : toggle ? (
                                    <div className="row no-gutters">
                                        {categorybyproduct.length ? (
                                            categorybyproduct.map((row, index) => (
                                                <div key={index} className="col-md-4">
                                                    <div className="item">
                                                        <div className="product">
                                                            <Link
                                                                to={{
                                                                    pathname: `/p/${row.id}`,
                                                                    state: row,
                                                                }}
                                                            >
                                                                <div className="product-header">
                                                                    <img
                                                                        crossOrigin="anonymous"
                                                                        className="img-fluid"
                                                                        src={row.photo}
                                                                        alt={row.name}
                                                                    />
                                                                </div>
                                                                <div className="product-body">
                                                                    <h5>{row.name}</h5>
                                                                    <h6>
                                                                        <strong>
                                                                            <span className="mdi mdi-approval" /> Code
                                                                        </strong>{" "}
                                                                        - {row.slug}
                                                                    </h6>
                                                                </div>
                                                            </Link>
                                                            <div className="product-footer">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-secondary btn-sm float-right"
                                                                    onClick={() => this.handleAddToCartClick(row)}
                                                                >
                                                                    <i className="mdi mdi-cart-outline" /> View
                                                                    Product
                                                                </button>
                                                                <p className="offer-price mb-0">
                                                                    &#x20B9;{row.stitches}{" "}
                                                                    <i className="mdi mdi-tag-outline" />
                                                                    <br />
                                                                    <span className="regular-price">
                                                                        &#x20B9;{row.height}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="text-danger">
                                                No products found in this category
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="row no-gutters">
                                        {list.length ? (
                                            list.map((row, index) => (
                                                <div key={index} className="col-md-4">
                                                    <div className="item">
                                                        <div className="product">
                                                            <Link
                                                                to={{
                                                                    pathname: `/p/${row.slug}/${row.id}`,
                                                                    state: row,
                                                                }}
                                                            >
                                                                <div className="product-header">
                                                                    <span className="badge badge-success">
                                                                        {row.discountPer}% OFF
                                                                    </span>
                                                                    <img
                                                                        className="img-fluid"
                                                                        src={row.photo}
                                                                        alt={row.name}
                                                                    />
                                                                    <span className="veg text-success mdi mdi-circle" />
                                                                </div>
                                                                <div className="product-body">
                                                                    <h5>{row.name}</h5>
                                                                    <h6>
                                                                        <strong>
                                                                            <span className="mdi mdi-approval" />{" "}
                                                                            Available in
                                                                        </strong>{" "}
                                                                        - {row.unitSize}
                                                                    </h6>
                                                                </div>
                                                            </Link>
                                                            <div className="product-footer">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-secondary btn-sm float-right"
                                                                    onClick={() => this.props.addToCart(row)}
                                                                >
                                                                    <i className="mdi mdi-cart-outline" /> Add To
                                                                    Cart
                                                                </button>
                                                                <p className="offer-price mb-0">
                                                                    &#x20B9;{row.netPrice}{" "}
                                                                    <i className="mdi mdi-tag-outline" />
                                                                    <br />
                                                                    <span className="regular-price">
                                                                        &#x20B9;{row.price}{" "}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="text-danger">
                                                Empty item in this category
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>

                {/* end product section */}
            </div>
        );
    }
}

export default connect(null, { addToCart })(Productview);
