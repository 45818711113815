import React, { Component } from 'react';
import { GetUserLogin, GetOrderDetails } from '../../../../../services';
import { NotificationManager } from 'react-notifications';
import Moment from 'react-moment';
import '../../css/index.css';

export default class List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: '',
            orderList: [],
            isLoading: true,
            error: null
        };
    }

    async componentDidMount() {
        let email = sessionStorage.getItem('email');
        if (email) {
            try {
                let value = await GetUserLogin.getCustomerDetail(email);

                if (value) {
                    // Fetch order details with product information
                    let orderDetails = await GetOrderDetails.getOrderDetailsWithProduct(value.id);

                    // Ensure orderDetails matches expected structure
                    this.setState({
                        user: value.data,
                        orderList: orderDetails || [], // Default to empty array if undefined
                        isLoading: false
                    });
                } else {
                    NotificationManager.error("Check your credentials", "Login");
                    this.setState({ isLoading: false });
                }
            } catch (error) {
                this.setState({ error: "Failed to fetch order details", isLoading: false });
            }
        } else {
            NotificationManager.error("No email found in session storage", "Login");
            this.setState({ isLoading: false });
        }
    }

    handleLogout = async (event) => {
        event.preventDefault();
        await GetUserLogin.logout();
    }

    render() {
        const { user, orderList, isLoading, error } = this.state;

        if (isLoading) {
            return <div>Loading...</div>;
        }

        if (error) {
            return <div>Error: {error}</div>;
        }

        return (
            <div className="wrapper">
                <div className="gambo-Breadcrumb">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">Home</li>
                                        <li className="breadcrumb-item active" aria-current="page">My Orders</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dashboard-group">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="user-dt">
                                    <div className="user-img">
                                        <img src="/img/avatar/img-5.jpg" alt="" />
                                        <div className="img-add">
                                            <input type="file" id="file" />
                                            <label htmlFor="file"><i className="uil uil-camera-plus" /></label>
                                        </div>
                                    </div>
                                    <h4>{user.fullName}</h4>
                                    <p>{user.phone}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-4">
                                <div className="left-side-tabs">
                                    <div className="dashboard-left-links">
                                        <a href="/account/view" className="user-item"><i className="uil uil-apps" />Overview</a>
                                        <a href="/account/profile" className="user-item"><i className="mdi mdi-account-outline" />My profile</a>
                                        <a href="/account/order" className="user-item active"><i className="uil uil-box" />My Orders</a>
                                        <a href="/account/wishlist" className="user-item"><i className="uil uil-heart" />Shopping Wishlist</a>
                                        <a href="/account/address" className="user-item"><i className="uil uil-location-point" />My Address</a>
                                        <a className="user-item" onClick={this.handleLogout}><i className="uil uil-exit" />Logout</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-8">
                                <div className="dashboard-right">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="main-title-tab">
                                                <h4><i className="uil uil-box" />My Orders</h4>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                            <div className="pdpt-bg">
                                                <div className="pdpt-title">
                                                    <h6>Order List</h6>
                                                </div>
                                                <div className="order-body10">
                                                    <div className="card card-body account-right">
                                                        <div className="widget">
                                                            <div className="order-list-tabel-main table-responsive">
                                                                <table className="datatabel table table-striped table-bordered order-list-tabel" width="100%" cellSpacing="0">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Date Purchased</th>
                                                                            <th>Product Image</th>
                                                                            <th>Variant Name</th>
                                                                            <th>Download</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            orderList && orderList.length > 0 ?
                                                                                orderList.map((order, index) => (
                                                                                    <tr key={index}>
                                                                                        <td><Moment format='MMMM Do YYYY'>{order.orderDate}</Moment></td>
                                                                                        <td><img src={order.productImage} alt="Product" width="50" height="50" /></td>
                                                                                        <td>{order.variantName}</td>
                                                                                        <td>
                                                                                            {order.variantPath ? (
                                                                                                <a href={order.variantPath} target="_blank" rel="noopener noreferrer">Download</a>
                                                                                            ) : (
                                                                                                <span>No Download</span>
                                                                                            )}
                                                                                        </td>
                                                                                    </tr>
                                                                                ))
                                                                                : <tr><td colSpan="4">No orders found.</td></tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
