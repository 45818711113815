// import React, { Component } from "react";
// import { Paper } from "@material-ui/core";
// import Slider from "react-slick";
// import { connect } from "react-redux";
// import { addToCart } from "../../../../store/actions/cartActions";
// import { GetProductDetails } from "../../../services";
// import "./index.css";

// class Singleproduct extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       product: null,
//       selectedVariants: [],
//       totalMoney: 0
//     };
//   }

//   async componentDidMount() {
//     window.scrollTo(0, 0);
//     let url = window.location.href.split("/");
//     let lastSegment = url.pop() || url.pop();
//     const productId = parseInt(lastSegment, 10);

//     const response = await GetProductDetails.getProductById(productId);

//     if (response && response.success) {
//       this.setState({ product: response.data });
//     }
//   }

//   handleCheckboxChange = (event, variant) => {
//     const { checked } = event.target;
//     this.setState((prevState) => {
//       const selectedVariants = checked
//         ? [...prevState.selectedVariants, variant]
//         : prevState.selectedVariants.filter((v) => v.variantName !== variant.variantName);

//       const totalMoney = selectedVariants.reduce((acc, curr) => acc + curr.variantPrice, 0);
//       return { selectedVariants, totalMoney };
//     });
//   };

//   handleAddToCart = () => {
//     if (this.state.selectedVariants.length === 0) {
//       alert("Please select at least one machine variant before adding to cart.");
//       return;
//     }
//     this.props.addToCart(this.state.product, this.state.selectedVariants); // Pass selectedVariants
//   };

//   render() {
//     const { product, totalMoney } = this.state;
//     const settings = {
//       customPaging: function (i) {
//         return (
//           <div id="sync1" className="owl-carousel">
//             <div className="item">
//               <img src={product?.photo} alt="product" />
//             </div>
//           </div>
//         );
//       },
//       dots: true,
//       dotsClass: "slick-dots slick-thumb",
//       infinite: true,
//       speed: 500,
//       slidesToShow: 1,
//       slidesToScroll: 1,
//     };

//     return (
//       <div>
//         <section className="pt-3 pb-3 page-info section-padding border-bottom bg-white single-product-header-bk">
//           <div className="container">
//             <div className="row">
//               <div className="col-md-12">
//                 <a href="#">
//                   <strong>
//                     <span className="mdi mdi-home" /> Home
//                   </strong>
//                 </a>
//                 <span className="mdi mdi-chevron-right" />{" "}
//                 <a href="#">Product</a>
//               </div>
//             </div>
//           </div>
//         </section>

//         <section className="shop-single section-padding pt-3">
//           <div className="container">
//             {product ? (
//               <div className="row">
//                 <div className="col-md-6">
//                   <div className="shop-detail-left">
//                     <Paper className="shop-detail-slider">
//                       <Slider {...settings}>
//                         <div>
//                           <img
//                             crossOrigin='anonymous'
//                             src={product.photo}
//                             className="img-fluid img-center"
//                             alt={product.name}
//                           />
//                         </div>
//                       </Slider>
//                     </Paper>
//                   </div>
//                 </div>
//                 <div className="col-md-6">
//                   <div className="shop-detail-right">
//                     <h1>{product.slug}</h1>
//                     <h2>{product.name}</h2>
//                     <h6>
//                       <strong>Dimensions</strong>
//                     </h6>
//                     <div className="pdp-product__old-price">
//                       <span>{product.height}</span>
//                     </div>
//                     <br />
//                     <h6>
//                       <strong>Stitch Details</strong>
//                     </h6>
//                     <div className="pdp-product__old-price">
//                       <span>{product.stitches}</span>
//                     </div>

//                     <h1>Select a Machine</h1>
//                     <h3>Total Money: ₹{totalMoney}</h3>
//                     <div>
//                       {product.productvariants.map((variant) => (
//                         <div key={variant.variantName} className="custom-control custom-checkbox">
//                           <input
//                             type="checkbox"
//                             className="custom-control-input"
//                             id={`variantCheck${variant.variantName}`}
//                             value={variant.variantPrice}
//                             onChange={(e) => this.handleCheckboxChange(e, variant)}
//                           />
//                           <label
//                             className="custom-control-label"
//                             htmlFor={`variantCheck${variant.variantName}`}
//                           >
//                             {variant.variantName} (₹{variant.variantPrice})
//                           </label>
//                         </div>
//                       ))}
//                     </div>

//                     <button
//                       type="button"
//                       className="btn btn-secondary btn-lg"
//                       onClick={this.handleAddToCart}
//                       disabled={this.state.selectedVariants.length === 0}
//                     >
//                       <i className="mdi mdi-cart-outline" /> Add To Cart
//                     </button>

//                     <h6 className="mb-3 mt-4">Product Description</h6>
//                     <div className="pdct-dts-1 short-desc">
//                       {product.sortDesc}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ) : (
//               "Loading..."
//             )}
//           </div>
//         </section>
//       </div>
//     );
//   }
// }

// export default connect(null, { addToCart })(Singleproduct);





import React, { Component } from "react";
import { Paper } from "@material-ui/core";
import Slider from "react-slick";
import { connect } from "react-redux";
import { addToCart } from "../../../../store/actions/cartActions";
import { GetProductDetails } from "../../../services";
import "./index.css";

class Singleproduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: null,
      selectedVariants: [],
      totalMoney: 0
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    let url = window.location.href.split("/");
    let lastSegment = url.pop() || url.pop();
    const productId = parseInt(lastSegment, 10);

    const response = await GetProductDetails.getProductById(productId);
    console.log(response);

    if (response && response.success) {
      this.setState({ product: response.data });
    }
  }

  handleCheckboxChange = (event, variant) => {
    const { checked } = event.target;
    this.setState((prevState) => {
      const selectedVariants = checked
        ? [...prevState.selectedVariants, variant]
        : prevState.selectedVariants.filter((v) => v.id !== variant.id);

      const totalMoney = selectedVariants.reduce((acc, curr) => acc + curr.variantPrice, 0);
      return { selectedVariants, totalMoney };
    });
  };

  handleAddToCart = () => {
    if (this.state.selectedVariants.length === 0) {
      alert("Please select at least one machine variant before adding to cart.");
      return;
    }
    this.props.addToCart(this.state.product, this.state.selectedVariants); // Pass selectedVariants
  };

  render() {
    const { product, totalMoney } = this.state;
    const settings = {
      customPaging: function (i) {
        return (
          <div id="sync1" className="owl-carousel">
            <div className="item">
              <img src={product?.photo} alt="product" />
            </div>
          </div>
        );
      },
      dots: true,
      dotsClass: "slick-dots slick-thumb",
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <div>
        <section className="pt-3 pb-3 page-info section-padding border-bottom bg-white single-product-header-bk">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <a href="#">
                  <strong>
                    <span className="mdi mdi-home" /> Home
                  </strong>
                </a>
                <span className="mdi mdi-chevron-right" />{" "}
                <a href="#">Product</a>
              </div>
            </div>
          </div>
        </section>

        <section className="shop-single section-padding pt-3 p-5">
          <div className="container">
            {product ? (
              <div className="row">
                <div className="col-md-6">
                  <div className="shop-detail-left">
                    <Paper className="shop-detail-slider">
                      <Slider {...settings}>
                        <div>
                          <img
                            crossOrigin='anonymous'
                            src={product.photo}
                            className="img-fluid img-center"
                            alt={product.name} style={{ borderRadius: "15px" }}
                          />
                        </div>
                      </Slider>
                    </Paper>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="shop-detail-right">
                    <h3>{product.slug}</h3>
                    <h4>{product.name}</h4>
                    <h6>
                      <strong>Dimensions</strong>
                    </h6>
                    <div className="pdp-product__old-price">
                      <span>{product.height}</span>
                    </div>
                    <br />
                    <h6>
                      <strong>Stitch Details</strong>
                    </h6>
                    <div className="pdp-product__old-price">
                      <span>{product.stitches}</span>
                    </div>

                    <h3>Select a Machine</h3>
                    <h3>Total Money: ₹{totalMoney}</h3>
                    <div>
                      {product.productvariants.map((variant) => (
                        <div key={variant.id} className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={`variantCheck${variant.id}`}
                            value={variant.variantPrice}
                            onChange={(e) => this.handleCheckboxChange(e, variant)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={`variantCheck${variant.id}`}
                          >
                            {variant.variantName} (₹{variant.variantPrice})
                          </label>
                        </div>
                      ))}
                    </div>

                    <button
                      type="button"
                      className="btn btn-secondary btn-lg"
                      onClick={this.handleAddToCart}
                      disabled={this.state.selectedVariants.length === 0}
                    >
                      <i className="mdi mdi-cart-outline" /> Add To Cart
                    </button>

                    <h6 className="mb-3 mt-4">Product Description</h6>
                    <div className="pdct-dts-1 short-desc">
                      {product.sortDesc}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              "Loading..."
            )}
          </div>
        </section>
      </div>
    );
  }
}

export default connect(null, { addToCart })(Singleproduct);
