const API_URL =
  document.domain === 'http://localhost:8080/backend'
    ? "localhost" // Local development URL
    : "https://inwiton.com/backend"; // Production URL


const Apis = {
  //Authentication api
  GetUserLogin: `${API_URL}/api/customer/login`,
  GetUserRegsiter: `${API_URL}/api/customer/register`,
  GetCustomerDetails: `${API_URL}/api/customer/getUserByEmailId?email=`,

  //product api
  GetProductById: `${API_URL}/api/product/getWebProductById?id=`,
  GetAllGroceryStaple: `${API_URL}/api/product/getAllGroceryStaple`,
  GetAllProductList: `${API_URL}/api/product/list/`,
  GetProductByCategory: `${API_URL}/api/product/getProductByCategory`,

  //product api
  GetOrderCreateByUser: `${API_URL}/api/order/create`,
  GetOrderByUser: `${API_URL}/api/order/list`,


  //Filter by category
  GetAllCategoryList: `${API_URL}/api/category/cn/list?slug=`,
  GetFilterByCategory: `${API_URL}/api/category/c`,
  GetAllCategorySubCategoryList: `${API_URL}/api/category/getAllCategory`,

  //profile 
  GetCustomerUpdateDetails: `${API_URL}/api/customer/update`,

  //Get location 
  GetLocationListDetails: `${API_URL}/api/location/list`,
  GetAreaListDetails: `${API_URL}/api/location/area/list/getbyid?id=`,

  //Get filter by product
  GetProductByFilter: `${API_URL}/api/product/gcatalogsearch/result?search=`,
  GetCategoryListByFilter: `${API_URL}/api/category/catlogsearch/child-category`,
  GetProductBySubcategory: `${API_URL}/api/category/catlogsearch/product`,


  //Razarpayment
  GetPaymentValue: `${API_URL}/api/payment/orders`,
  GetPaymentVerification: `${API_URL}/api/payment/verification`,
  GetPaymentOrderList: `${API_URL}/api/payment/orderlist`,
  GetOrdersListByCustomer: `${API_URL}/api/order/list`,
  GetOrderDetailsWithProduct: `${API_URL}/api/order/details`,

  // Wishlist API
  AddWishlistItem: `${API_URL}/api/wishlist/add`, // Add a new item to the wishlist
  RemoveWishlistItem: `${API_URL}/api/wishlist/remove`, // Remove an item from the wishlist
  GetWishlistItems: `${API_URL}/api/wishlist/find`, // Get all wishlist items for a user
  GetWishlistItemById: `${API_URL}/api/wishlist/item/`, // Get a specific wishlist item by its ID
  UpdateWishlistItem: `${API_URL}/api/wishlist/update` // Update details of a specific wishlist item

};
export { API_URL, Apis };
