import React, { Component } from 'react';
import Login from '../../auth/login';
import { withRouter } from 'react-router-dom';
import Cartsidebar from '../web/views/cart-sidebar';
import { GetUserLogin } from '../services';
import './style.css'; // Include the updated CSS file
import { GetCategoryDetails } from '../services';

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: '',
            userName: '',
            searchtxt: '',
            categories: [],
            activeCategory: null,
            dropdownVisible: false,
        };
    }

    async componentDidMount() {
        let cookies = await GetUserLogin.isAuthenticate();
        this.setState({ token: cookies });
        let email = sessionStorage.getItem('email');
        if (email) {
            let user = await GetUserLogin.getCustomerDetail(email);
            if (user) {
                this.setState({ userName: user.data.fullName });
            }
        }

        // Fetch categories
        this.fetchCategories();
    }

    fetchCategories = async () => {
        try {
            const list = await GetCategoryDetails.getAllCategorySubCategoryList();
            this.setState({ categories: list.data });
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleLogout = async (event) => {
        event.preventDefault();
        await GetUserLogin.logout();
    };

    handleClickSearch = (event) => {
        let { searchtxt } = this.state;
        this.props.history.push(`/product/catalogsearch/result/${searchtxt}`);
    };

    toggleDropdown = () => {
        this.setState((prevState) => ({ dropdownVisible: !prevState.dropdownVisible }));
    };

    handleCategoryHover = (categoryId) => {
        this.setState({ activeCategory: categoryId });
    };

    handleCategoryLeave = () => {
        this.setState({ activeCategory: null });
    };

    render() {
        const { token, userName, searchtxt, categories, activeCategory, dropdownVisible } = this.state;
        return (
            <div>
                <header className="header clearfix">
                    <div className="navbar-top bg-dark pt-2 pb-2">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12 text-center">
                                    <a href="/" className="mb-0 text-white">
                                        Welcome to Embroidery Designing - <strong><span className="text-light">JC Creations</span></strong>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className="navbar navbar-light navbar-expand-lg">
                        <div className="container-fluid">
                            <a className="navbar-brand" href="/">
                                <img src="/img/jclogo.png" alt="logo" />
                            </a>
                            <button
                                className="navbar-toggler"
                                type="button"
                                onClick={this.toggleDropdown}
                                aria-controls="navbarNavDropdown"
                                aria-expanded={dropdownVisible}
                                aria-label="Toggle navigation"
                            >
                                <span className="navbar-toggler-icon" />
                            </button>
                            <div className={`navbar-collapse ${dropdownVisible ? 'show' : ''}`} id="navbarNavDropdown">
                                <div className="navbar-nav mr-auto mt-2 mt-lg-0">
                                    <div className={`top-categories-search-main ${dropdownVisible ? 'd-block' : 'd-none'}`}>
                                        <div className={`top-categories-search`}>
                                            <input
                                                className="form-control"
                                                placeholder="Search"
                                                aria-label="Search products in Your City"
                                                type="text"
                                                name="searchtxt"
                                                value={searchtxt}
                                                onChange={this.handleChange}
                                            />
                                            <button className="btn btn-secondary" onClick={this.handleClickSearch}>
                                                <i className="mdi mdi-file-find" /> Search
                                            </button>
                                        </div>
                                    </div>
                                    <ul className="navbar-nav">
                                        <li className="nav-item dropdown">
                                            <a
                                                className="nav-link dropdown-toggle"
                                                href="#"
                                                role="button"
                                                aria-haspopup="true"
                                                aria-expanded={dropdownVisible}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.toggleDropdown();
                                                }}
                                            >
                                                Categories
                                            </a>
                                            <div className={`dropdown-menu ${dropdownVisible ? 'show' : ''}`}>
                                                {categories.map((category) => (
                                                    <div
                                                        key={category.id}
                                                        className="dropdown-submenu"
                                                        onMouseEnter={() => this.handleCategoryHover(category.id)}
                                                        onMouseLeave={this.handleCategoryLeave}
                                                    >
                                                        <a
                                                            className="dropdown-item dropdown-toggle"
                                                            href="#"
                                                            role="button"
                                                            aria-expanded={activeCategory === category.id}
                                                        >
                                                            {category.name}
                                                        </a>
                                                        {category.SubCategories && category.SubCategories.length > 0 && activeCategory === category.id && (
                                                            <div className="dropdown-menu show">
                                                                {category.SubCategories.map((subCategory) => (
                                                                    <a
                                                                        key={subCategory.id}
                                                                        className="dropdown-item"
                                                                        href='/product/catalogsearch/result'
                                                                    >
                                                                        {subCategory.sub_name}
                                                                    </a>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="https://www.youtube.com/watch?v=UAPpNfXqFCo&feature=youtu.be">
                                                How to Buy Designs (తెలుగు)
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="https://www.youtube.com/watch?v=di3FWmT3QnY">
                                                How to Buy Designs (ಕನ್ನಡ)
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="https://drive.google.com/drive/folders/1E3v2HpcRObeTQvkpUNlVrDav-3XAUWU_">
                                                Catalog
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="navbar-nav ml-auto">
                                    <ul className="list-inline main-nav-right">
                                        <li className="list-inline-item">
                                            <a
                                                data-target="#bd-example-modal"
                                                data-toggle="modal"
                                                className="btn btn-link"
                                                style={token ? { display: 'none' } : { display: 'block' }}
                                            >
                                                <i className="mdi mdi-account-circle" /> Login/Sign Up
                                            </a>
                                            <div className="dropdown" style={token ? { display: 'block' } : { display: 'none' }}>
                                                <button className="btn btn-account dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {userName}
                                                </button>
                                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <a className="dropdown-item" href="/account/view">
                                                        <i className="uil uil-apps" /> Dashboard
                                                    </a>
                                                    <a className="dropdown-item" href="/account/profile">
                                                        <i className="mdi mdi-account-outline" aria-hidden="true"></i> My Profile
                                                    </a>
                                                    <a className="dropdown-item" href="/account/wishlist">
                                                        <i className="mdi mdi-heart-outline" aria-hidden="true"></i> Wish List
                                                    </a>
                                                    <a className="dropdown-item" href="/account/order/list">
                                                        <i className="mdi mdi-format-list-bulleted" aria-hidden="true"></i> Orders List
                                                    </a>
                                                    <div className="dropdown-divider"></div>
                                                    <span className="dropdown-item" onClick={this.handleLogout}>
                                                        <i className="mdi mdi-lock" aria-hidden="true"></i> Logout
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-inline-item cart-btn">
                                            <Cartsidebar />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>
                <Login />
            </div>
        );
    }
}

export default withRouter(Navigation);
