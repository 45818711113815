import React, { Component } from 'react';
import Slider from "react-slick";
import { Link, withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GetProductDetails } from '../../../../services'; // Import your service function

class Topsavers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productlist: [],
            isLoaded: false,
            categoryId: '2',
        };
    }

    async componentDidMount() {
        const categoryId = '2'; // Replace with the actual category ID you need        
        const response = await GetProductDetails.getProductByCategory(categoryId);

        if (response) {
            this.setState({
                productlist: response.data,
                isLoaded: true
            });
        } else {
            this.setState({
                isLoaded: true
            });
        }
    }

    handleAddToCartClick = (product) => {
        // Navigate to the SingleProduct page
        this.props.history.push(`/p/${product.id}`);
    }

    render() {
        const { productlist, isLoaded } = this.state;

        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 4,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        initialSlide: 3
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <div>
                <section className="product-items-slider section-padding">
                    <div className="container" id="header-category-bk">
                        <div className="section-header">
                            <span>Blouse</span>
                            <h5 className="heading-design-h5">Embroidery Items
                                {/* <Link to="/shop/embroidery"><span className="float-right text-secondary">View All</span></Link> */}
                            </h5>
                        </div>
                        <Slider {...settings}>
                            {!isLoaded ?
                                <div className="progress-bar-bk"><CircularProgress color="secondary" /></div> :
                                productlist.map((row, index) => (
                                    <div key={index} className="item">
                                        <div className="product">
                                            <Link to={`/p/${row.id}`}>
                                                <div className="product-header">

                                                    <img crossOrigin='anonymous' className="img-fluid" src={row.photo} alt={row.name} />
                                                </div>
                                                <div className="product-body">
                                                    <h5>{row.name}</h5>
                                                    <h6><strong><span className="mdi mdi-approval" /> Code</strong> - {row.slug}</h6>
                                                </div>
                                            </Link>
                                            <div className="product-footer">
                                                <button type="button" className="btn btn-secondary btn-sm float-right" onClick={() => this.handleAddToCartClick(row)}>
                                                    <i className="mdi mdi-cart-outline" /> View Product
                                                </button>
                                                {/* <p className="offer-price mb-0">&#x20B9;{row.stitches} <i className="mdi mdi-tag-outline" /><br /><span className="regular-price">&#x20B9;{row.height}</span></p> */}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </Slider>
                    </div>
                </section>
            </div>
        );
    }
}

export default withRouter(Topsavers);
